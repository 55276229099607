import React, { useState } from 'react';
import { DonwloadIcon, EyeIcon, UploadIcon } from '../../Utils/Icons';
import UserRequestConfirmation from './userRequestConfirmation';
import Tooltip from '../Tooltip';
import SuccessModal from './userRequestSend';

const UserRequest: React.FC = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [selectedUserType, setSelectedUserType] = useState<string | null>(null);
    const [username, setUsername] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [email, setEmail] = useState('');
    const [entity, setEntity] = useState('');
    const [location, setLocation] = useState('');
    const [work, setWork] = useState('');
    const [justification, setJustification] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [errors, setErrors] = useState({
        username: '',
        userPassword: '',
        email: '',
        entity: '',
        location: '',
        work: '',
        justification: '',
        selectedUserType: '',
    });

    const maxLength = 200;

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setJustification(event.target.value);
    };

    const handleUserTypeSelect = (type: string) => {
        setSelectedUserType(type);
        setErrors((prevErrors) => ({ ...prevErrors, selectedUserType: '' }));
        if (type !== 'Técnico') {
            setLocation('');
            setWork('');
        }
    };

    const handleEmailBlur = async () => {
        if (email) {
            try {
                const response = await fetch(`${API_URL}/users/check-email?email=${email}`);
                const data = await response.json();
                if (data.exists) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        email: 'Ya existe un usuario registrado con este correo.',
                    }));
                } else {
                    setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
                }
            } catch (error) {
                console.error('Error al verificar el correo:', error);
            }
        }
    };

    const handleSolicitarClick = async (e: React.MouseEvent) => {
        e.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const newErrors = {
            username: !username ? 'El nombre es obligatorio.' : '',
            userPassword: !userPassword ? 'El número de documento es obligatorio.' : '',
            email: !email
                ? 'El correo electrónico es obligatorio.'
                : !emailRegex.test(email)
                ? 'Por favor, ingresa un correo válido.'
                : errors.email,
            entity: !entity ? 'La institución es obligatoria.' : '',
            justification: !justification ? 'La justificación es obligatoria.' : '',
            selectedUserType: !selectedUserType ? 'Debes seleccionar un tipo de usuario.' : '',
            location:
                selectedUserType === 'Técnico' && !location
                    ? 'El municipio es obligatorio para técnicos.'
                    : '',
            work: selectedUserType === 'Técnico' && !work ? 'El área de trabajo es obligatoria para técnicos.' : '',
        };
        setErrors(newErrors);
        if (Object.values(newErrors).some((error) => error)) {
            return;
        }
        setShowConfirmation(true);
    };

    const handleConfirm = async () => {
        const requestData = {
            username,
            userPassword,
            email,
            entity,
            location,
            work,
            justification,
            userType: selectedUserType,
        };

        try {
            const response = await fetch(`${API_URL}/user-requests`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            if (response.ok) {
                setShowConfirmation(false);
                setShowSuccessModal(true);
            } else {
                const error = await response.json();
                alert(`Error al enviar la solicitud: ${error.message}`);
            }
        } catch (error) {
            console.error('Error al enviar la solicitud:', error);
            alert('Hubo un error al enviar la solicitud.');
        }

        setShowConfirmation(false);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
        window.location.reload();
    };

    return (
        <main className="flex-grow bg-gris1 ">
            <div className="bg-cover bg-center md:h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Solicitar Cuenta</h1>
                </div>
            </div>
            <div className="flex justify-center items-center p-12">
                <div className="w-full max-w-md bg-white p-8 border border-primary rounded-md shadow-lg">
                    <form>
                        <div className="mb-4">
                            <label className="block text-sm font-semibold">Nombre Completo</label>
                            <input
                                className={`w-full p-2 border ${errors.username ? 'border-red-500' : 'border-primary'} rounded-md`}
                                type="text"
                                placeholder="Nombre"
                                maxLength={35}
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            {errors.username && <span className="text-red-500 text-sm">{errors.username}</span>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-semibold">Número de Documento</label>
                            <input
                                className={`w-full p-2 border ${errors.userPassword ? 'border-red-500' : 'border-primary'} rounded-md`}
                                type="text"
                                placeholder="Número de documento"
                                maxLength={16}
                                value={userPassword}
                                onChange={(e) => setUserPassword(e.target.value)}
                            />
                            {errors.userPassword && <span className="text-red-500 text-sm">{errors.userPassword}</span>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-semibold">Correo electrónico</label>
                            <input
                                className={`w-full p-2 border ${errors.email ? 'border-red-500' : 'border-primary'} rounded-md`}
                                type="email"
                                placeholder="Correo electrónico"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onBlur={handleEmailBlur}
                            />
                            {errors.email && <span className="text-red-500 text-sm">{errors.email}</span>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-semibold">Institución
                                <Tooltip text="La institución a la que pertenece, por ejemplo: AMVA, Alcaldía de Bello" />
                            </label>
                            <input
                                className={`w-full p-2 border ${errors.entity ? 'border-red-500' : 'border-primary'} rounded-md`}
                                type="text"
                                placeholder="Institución"
                                maxLength={51}
                                value={entity}
                                onChange={(e) => setEntity(e.target.value)}
                            />
                            {errors.entity && <span className="text-red-500 text-sm">{errors.entity}</span>}
                        </div>
                        {/* Selección del tipo de usuario */}
                        <div className="mb-6">
                            <label className="block text-sm font-semibold mb-2">Tipo de usuario a solicitar</label>
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                                <div className={`p-3 border rounded-md cursor-pointer ${selectedUserType === 'Ciudadano' ? 'border-analist' : 'border-gray-300'}`}
                                    onClick={() => handleUserTypeSelect('Ciudadano')}
                                >
                                    <h3 className="text-analist font-bold mb-3 text-center">Ciudadano</h3>
                                    <div className="grid grid-cols-2 gap-1 items-center">
                                        <EyeIcon className="w-8 h-8 text-analist" />
                                        <p className="text-xs text-analist">Vista de la data.</p>
                                        <DonwloadIcon className="w-8 h-8 text-secondary" />
                                        <p className="text-xs text-secondary">Descarga de la data.</p>
                                        <UploadIcon className="w-10 h-10 text-secondary" />
                                        <p className="text-xs text-secondary">Subida de data y reportes.</p>
                                    </div>
                                </div>
                                <div className={`p-3 border rounded-md cursor-pointer ${selectedUserType === 'Técnico' ? 'border-cience' : 'border-gray-300'}`}
                                    onClick={() => handleUserTypeSelect('Técnico')}
                                >
                                    <h3 className="text-cience font-bold mb-3 text-center">Técnico</h3>
                                    <div className="grid grid-cols-2 gap-1 items-center">
                                        <EyeIcon className="w-8 h-8 text-cience" />
                                        <p className="text-xs text-cience">Vista de la data.</p>
                                        <DonwloadIcon className="w-8 h-8 text-cience" />
                                        <p className="text-xs text-cience">Descarga de la data.</p>
                                        <UploadIcon className="w-10 h-10 text-secondary" />
                                        <p className="text-xs text-secondary">Subida de data y reportes.</p>
                                    </div>
                                </div>
                                <div className={`p-2 border rounded-md cursor-pointer ${selectedUserType === 'Tomador de Decisiones' ? 'border-cience2' : 'border-gray-300'}`}
                                    onClick={() => handleUserTypeSelect('Tomador de Decisiones')}
                                >
                                    <h3 className="text-cience2 font-bold text-sm text-center">Tomador de Decisiones</h3>
                                    <div className="grid grid-cols-2 gap-1 items-center">
                                        <EyeIcon className="w-8 h-8 text-cience2" />
                                        <p className="text-xs text-cience2">Vista de la data.</p>
                                        <DonwloadIcon className="w-8 h-8 text-cience2" />
                                        <p className="text-xs text-cience2">Descarga de la data.</p>
                                        <UploadIcon className="w-10 h-10 text-cience2" />
                                        <p className="text-xs text-cience2">Subida de data y reportes.</p>
                                    </div>
                                </div>
                            </div>
                            {errors.selectedUserType && <span className="text-red-500 text-sm">{errors.selectedUserType}</span>}
                        </div>
                        {/* Campos adicionales para Técnico */}
                        {selectedUserType === 'Técnico' && (
                            <>
                                <div className="mb-4">
                                    <label className="block text-sm font-semibold">Municipio
                                        <Tooltip text="El municipio al que pertenece, por ejemplo: Envigado, Medellín" />
                                    </label>
                                    
                                    <input
                                        className={`w-full p-2 border ${errors.location ? 'border-red-500' : 'border-primary'} rounded-md`}
                                        type="text"
                                        placeholder="Municipio"
                                        value={location}
                                        onChange={(e) => setLocation(e.target.value)}
                                    />
                                    {errors.location && <span className="text-red-500 text-sm">{errors.location}</span>}
                                </div>
                                <div className="mb-4">
                                    <label className="block text-sm font-semibold">Área de Trabajo
                                        <Tooltip text="Ejemplo: Secretaría de Ambiente, Secretaría de Salud, etc." />
                                    </label>
                                    <input
                                        className={`w-full p-2 border ${errors.work ? 'border-red-500' : 'border-primary'} rounded-md`}
                                        type="text"
                                        placeholder="Área de Trabajo"
                                        value={work}
                                        onChange={(e) => setWork(e.target.value)}
                                    />
                                    {errors.work && <span className="text-red-500 text-sm">{errors.work}</span>}
                                </div>
                            </>
                        )}
                        <div className="mb-6">
                            <label className="block text-sm font-semibold">Justificación</label>
                            <div className="relative">
                                <textarea
                                    className={`w-full p-2 border ${errors.justification ? 'border-red-500' : 'border-primary'} rounded-md`}
                                    rows={4}
                                    placeholder="Escriba una justificación detallada."
                                    maxLength={maxLength}
                                    value={justification}
                                    onChange={handleChange}
                                ></textarea>
                                <span className="absolute bottom-2 right-2 text-sm text-primary">
                                    {justification.length}/{maxLength} caracteres
                                </span>
                                {errors.justification && <span className="text-red-500 text-sm">{errors.justification}</span>}
                            </div>
                        </div>
                        <button
                            onClick={handleSolicitarClick}
                            className="w-full bg-primary text-textColor py-2 rounded-md"
                        >
                            Solicitar
                        </button>
                    </form>
                </div>
            </div>
            {showConfirmation && selectedUserType && (
                <UserRequestConfirmation
                    userType={selectedUserType}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}
            {showSuccessModal && (
                <SuccessModal
                    message="Solicitud enviada exitosamente."
                    onClose={handleCloseSuccessModal}
                />
            )}
        </main>
    );
};

export default UserRequest;
