import React, { useEffect, useState } from 'react';

interface UserRequest {
    _id: string;
    username: string;
    userPassword: string;
    email: string;
    entity: string;
    location?: string;
    work?: string;
    justification: string;
    userType: string;
    status: string;
    createdAt: string;
}

const AdminUserRequest: React.FC = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [userRequests, setUserRequests] = useState<UserRequest[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [notification, setNotification] = useState<string | null>(null);

    useEffect(() => {
        const fetchRequests = async () => {
            try {
                const response = await fetch(`${API_URL}/user-requests`);
                if (!response.ok) {
                    throw new Error('Error al obtener las solicitudes.');
                }
                const data = await response.json();
                const sortedRequests = data.sort((a: UserRequest, b: UserRequest) => 
                    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                );
                setUserRequests(sortedRequests);
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                } else {
                    setError('Ocurrió un error desconocido.');
                }
            }
        };

        fetchRequests();
    }, []);

    const handleUpdateStatus = async (id: string, status: string) => {
        try {
            const response = await fetch(`${API_URL}/user-requests/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status }),
            });
            const result = await response.json();
            if (!response.ok) {
                throw new Error(result.message || 'Error al actualizar el estado de la solicitud.');
            }
            setUserRequests((prevRequests) =>
                prevRequests.map((request) =>
                    request._id === id ? { ...request, status } : request
                )
            );
            setNotification(`Solicitud ${status === 'approved' ? 'aprobada' : 'rechazada'} exitosamente.`);
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message);
            } else {
                setError('Ocurrió un error desconocido.');
            }
        }
    };

    const closeNotification = () => {
        setNotification(null);
    };

    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center md:h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-2xl md:text-5xl font-bold">Solicitudes de Usuarios</h1>
                </div>
            </div>

            <div className="container overflow-x-auto p-2 sm:p-8 mx-auto">
                {error && <p className="text-red-500 text-center mb-4">{error}</p>}
                {notification && (
                    <div className="text-center bg-green-200 text-green-800 p-4 rounded-md mb-4">
                        {notification}
                        <button
                            onClick={closeNotification}
                            className="ml-4 text-sm text-green-600 underline"
                        >
                            Cerrar
                        </button>
                    </div>
                )}
                {userRequests.length > 0 ? (
                    <table className="min-w-full border border-gris2 text-left">
                        <thead>
                            <tr className="bg-gris1 text-center">
                                <th className="p-2 border border-gris2">Nombre</th>
                                <th className="p-2 border border-gris2">Email</th>
                                <th className="p-2 border border-gris2">Entidad</th>
                                <th className="p-2 border border-gris2">Rol</th>
                                <th className="p-2 border border-gris2">Fecha de Creación</th>
                                <th className="p-2 border border-gris2 sm:w-1/2 md:w-1/3"> {' '}{' '}Justificación {' '}{' '}</th>
                                <th className="p-2 border border-gris2">Estado</th>
                                <th className="p-2 border border-gris2">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userRequests.map((request) => (
                                <tr key={request._id} className="hover:bg-gray-50">
                                    <td className="p-2 border border-gris2">{request.username}</td>
                                    <td className="p-2 border border-gris2">{request.email}</td>
                                    <td className="p-2 border border-gris2">{request.entity}</td>
                                    <td
                                        className={`p-2 border border-gris2 text-center font-bold ${
                                            request.userType === 'Técnico'
                                                ? 'text-cience'
                                                : request.userType === 'Ciudadano'
                                                ? 'text-analist'
                                                : 'text-cience2'
                                        }`}
                                    >
                                        {request.userType}
                                    </td>
                                    <td className="p-2 border border-gris2">
                                        {new Date(request.createdAt).toLocaleString('es-CO')}
                                    </td>
                                    <td className="p-2 border border-gris2 sm:w-1/2 md:w-1/3">{request.justification}</td>
                                    <td className="p-2 border border-gris2">
                                        {request.status === 'pending'
                                            ? 'Pendiente'
                                            : request.status === 'approved'
                                            ? 'Aprobada'
                                            : 'Rechazada'}
                                    </td>
                                    <td className="p-2 flex flex-col items-center space-y-2 mt-4">
                                        <button
                                            className={`px-4 py-2 rounded-md ${
                                                request.status !== 'pending' ? 'bg-gray-400 text-gray-600 cursor-not-allowed' : 'bg-primary text-white hover:bg-primary/80'
                                            }`}
                                            onClick={() => handleUpdateStatus(request._id, 'approved')}
                                            disabled={request.status !== 'pending'}
                                        >
                                            Aprobar
                                        </button>
                                        <button
                                            className={`px-4 py-2 rounded-md ${
                                                request.status !== 'pending' ? 'bg-gray-400 text-gray-600 cursor-not-allowed' : 'bg-red-600 text-white hover:bg-red-500'
                                            }`}
                                            onClick={() => handleUpdateStatus(request._id, 'rejected')}
                                            disabled={request.status !== 'pending'}
                                        >
                                            Denegar
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p className="text-center text-gray-600">No hay solicitudes registradas.</p>
                )}
            </div>
        </main>
    );
};

export default AdminUserRequest;
