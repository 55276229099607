interface SuccessModalProps {
    message: string;
    onClose: () => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ message, onClose }) => {
    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-80 text-center border border-primary">
                <p className="mb-6">{message}</p>
                <button
                    onClick={onClose}
                    className="bg-primary text-white px-4 py-2 rounded-md"
                >
                    Aceptar
                </button>
            </div>
        </div>
    );
};

export default SuccessModal;
