import React from 'react';
import { useAuth } from '../Authentication';
const UserManual: React.FC = () => {
    const { user } = useAuth();

    let manualSrc;
    if (user?.features.rol === 'default') {
        manualSrc = "/Manual de Usuario Ciudadano.pdf";
    } else if (user?.features.rol === 'Técnico') {
        manualSrc = "/Manual de Usuario Técnico.pdf";
    } else if (user?.features.rol === 'Administrador') {
        manualSrc = "/Manual de Usuario Administrador.pdf";
    } else if (user?.features.rol === 'Tomador de Decisiones') {
        manualSrc = "";
    } else {
        manualSrc = "/Manual de Usuario Ciudadano.pdf";
    }

    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center md:h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Manual de Usuario</h1>
                </div>
            </div>
            <div className="p-4 sm:p-8 max-w-screen-lg mx-auto">
            <iframe 
                src={manualSrc} 
                width="100%" 
                height="600px" 
                className="border-2 border-secondary"
            ></iframe>
            </div>
        </main>
    );
};

export default UserManual;
