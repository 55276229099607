import React from 'react';
import NormatividadNacional from './Normatives/nationalNormative';
import AcuerdoInternacional from './Normatives/acuerdosInternacionales';
import NormatividadNacionalSaludAmbiental from './Normatives/nnSaludAmbiental';
import NormatividadNacionalSalud from './Normatives/nnSalud';
import NormatividadLocal from './Normatives/normatividadLocal';

const Normativa: React.FC = () => {
    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center md:h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Normatividad</h1>
                </div>
            </div>
            <AcuerdoInternacional/>
            <NormatividadNacional/>
            <NormatividadNacionalSaludAmbiental/>
            <NormatividadNacionalSalud/>
            <NormatividadLocal/>
        </main>
    );
};

export default Normativa;
