import React, { useState } from 'react';

const ContactPague: React.FC = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await fetch(`${API_URL}/pqrs`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username: formData.name,
                    email: formData.email,
                    message: formData.message,
                }),
            });
    
            if (response.ok) {
                alert('PQRS enviada con éxito');
                setFormData({ name: '', email: '', message: '' });
            } else {
                alert('Error al enviar la PQRS');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error al enviar la PQRS');
        }
    };
    
    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center md:h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">
                        Peticiones, Quejas, Sugerencias y Reclamos (PQSR)
                    </h1>
                </div>
            </div>
            <div className="flex justify-center items-center min-h-screen py-8">
                <div className="w-full max-w-md bg-white p-8 border border-primary rounded-md shadow-lg">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-sm font-semibold">Nombre</label>
                            <input
                                className="w-full p-2 border border-primary rounded-md"
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Nombre"
                                required
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-sm font-semibold">Correo electrónico</label>
                            <input
                                className="w-full p-2 border border-primary rounded-md"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Correo electrónico"
                                required
                            />
                        </div>

                        <div className="mb-6 relative">
                            <label className="block text-sm font-semibold">Mensaje</label>
                            <textarea
                                className="w-full p-2 border border-primary rounded-md"
                                name="message"
                                rows={4}
                                maxLength={300}
                                value={formData.message}
                                onChange={handleChange}
                                placeholder="Escribe tu solicitud..."
                                required
                            ></textarea>
                            <span className="absolute bottom-2 right-2 text-sm text-primary">
                                {formData.message.length}/{300} caracteres
                            </span>
                        </div>

                        <button
                            type="submit"
                            className="w-full bg-primary text-textColor py-2 rounded-md"
                        >
                            Enviar
                        </button>
                    </form>
                </div>
            </div>
        </main>
    );
};

export default ContactPague;
