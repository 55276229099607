import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

export type Role = 'default' | 'Técnico' | 'Cientifico' | 'Tomador de Decisiones' | 'Administrador';

interface User {
    username: string;
    userPassword: string;
    features: {
        entity: string;
        rol: Role;
        work?: string;
        location?: string;
        email: string;
    };
}

interface AuthContextType {
    user: User | null;
    login: (user: User) => void;
    logout: () => void;
    hasRole: (role: Role) => boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    const login = (user: User) => {
        localStorage.setItem('user', JSON.stringify(user));
        setUser(user);
    };

    const logout = async () => {
        if (user) {
            try {
                await fetch(`${process.env.REACT_APP_API_URL}/users/logout`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        email: user.features.email,
                    }),
                });
            } catch (error) {
                console.error('Error al registrar el cierre de sesión:', error);
            }
        }
    
        localStorage.removeItem('user');
        setUser(null);
    };

    const hasRole = (role: Role) => user?.features.rol === role;

    return (
        <AuthContext.Provider value={{ user, login, logout, hasRole }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth debe usarse dentro de un AuthProvider');
    }
    return context;
};
