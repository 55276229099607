import React from 'react';
import Categorias from './accionesPermanentesCategories';
import Disminuir from './accionesPermanentesDisminuir';

const AccionesPermanentes: React.FC = () => {
    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center md:h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">¿Cómo puedo contribuir?</h1>
                </div>
            </div>
            <div className="p-4 sm:p-8 text-justify text-base md:text-lg max-w-screen-lg mx-auto">
                <p className="border border-primary rounded-md p-4">
                    Vivimos en un área metropolitana en constante crecimiento y densamente poblada, por lo que es fundamental tomar conciencia de la importancia de nuestro aporte individual para mejorar la calidad del aire. Apoyar con nuestras acciones los esfuerzos que se realizan desde los ámbitos institucionales y empresariales es esencial. A continuación, te compartimos algunas recomendaciones que puedes adoptar en casa:
                </p>
            </div>
            <div className="p-2 flex flex-col md:flex-row items-start max-w-screen-xl mx-auto space-y-4 md:space-y-0 md:space-x-4">
                <div className="border border-primary rounded-md flex flex-col items-center w-full md:w-1/2 h-full">
                    <p className="text-lg text-primary font-bold text-center mb-4">Acciones para contribuir disminuir la contaminación</p>
                    <Disminuir />
                </div>

                <div className="max-w-2xl mx-auto justify-center items-center text-center border border-primary rounded-md">
                    <p className="text-lg text-primary font-bold text-center mb-4">Acciones para disminuir la exposición a la contaminación</p>
                    <p className='text-sm font-semibold'>¿Cuáles son las estrategias para disminuir el tiempo que estamos expuestos a la contaminación del aire?</p>
                    <Categorias />
                </div>
            </div>

            <br/>
        </main>
    );
};

export default AccionesPermanentes;
