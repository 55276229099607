import React from 'react';

interface TooltipProps {
    text: string;
}

const Tooltip: React.FC<TooltipProps> = ({ text }) => (
    <span className="relative group">
        <span className="ml-2 mb-1 inline-flex items-center justify-center w-4 h-4 rounded-full bg-primary text-textColor align-middle">
        ℹ        
        </span>
        <div className="absolute left-0 hidden w-48 p-2 text-sm text-white bg-black rounded-md group-hover:block z-10">
            {text}
        </div>
    </span>
);

export default Tooltip;
