import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth, Role } from '../../../Authentication';

const roleConfig: Partial<Record<Role, { title: string; reports: string[] }>> = {
    default: {
        title: "Resumen de enfermedades relacionados a la contaminación del aire en el período 2008 a 2019",
        reports: [
            "https://app.powerbi.com/view?r=eyJrIjoiNDkxZmJjNGQtMmZjNi00YmZhLTgwNDAtYTFlZDljNTI3YmM3IiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9",
            "https://app.powerbi.com/view?r=eyJrIjoiYTJlMzNlMWMtMDM4OC00MTQyLWI4MmMtMmU5NTBjZDBiNzJmIiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9&amp;pageName=ReportSection",
            "https://app.powerbi.com/view?r=eyJrIjoiZTkwMzc2NWMtNzY1Mi00ZmZiLWI3YzUtNmY5ZjQwZjc3NWNlIiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9",
            "https://app.powerbi.com/view?r=eyJrIjoiNTE2MGI5NzUtYTJiOC00Y2Y1LTk4Y2YtZjc3NGRmNTIxZTI1IiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9"
        ]
    },
    Técnico: {
        title: "Distribución porcentual de los casos de enfermedades relacionados a la calidad del aire (Número de efectos por año/Número de efectos del periodo)",
        reports: [
            "https://app.powerbi.com/view?r=eyJrIjoiMGIwZmIxYTItNjIwOC00NDJiLThiMmEtNDc3M2Y0ODAyYjQ0IiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9",
            "https://app.powerbi.com/view?r=eyJrIjoiMTliZmM3MGItMWI3Yi00M2RjLThiZGItOThhNTFlZGE4MGU3IiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9",
            "https://app.powerbi.com/view?r=eyJrIjoiYjcyYjQ2NGUtM2VkMy00NmUyLTg2MTAtZTU0ODcxNDVjNGM5IiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9",

        ]
    }
};

const getRoleConfig = (role: Role): { title: string; reports: string[] } => {
    return roleConfig[role] || roleConfig.default!;
};

const Morbilidad: React.FC = () => {
    const { user } = useAuth();
    const { title, reports } = getRoleConfig(user?.features.rol || 'default');
    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center md:h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Morbilidad</h1>
                    <br />
                    <div>
                        <Link to="/eventos/agudos" className="px-4 py-2 hover:bg-primary rounded-md">Volver</Link>
                    </div>
                </div>
            </div>
            <div className="p-4 sm:p-8 text-justify text-base md:text-lg max-w-screen-lg mx-auto">
                <div className="border border-primary rounded-md p-4">
                    <h2 className="font-bold text-lg text-center">¿Cuál ha sido el comportamiento de los casos de atención en salud por enfermedades relacionadas con la calidad del aire en los municipios del Valle de Aburrá?</h2>
                    <p className="mt-2">Las enfermedades agudas están directamente relacionadas con la calidad del aire. Este informe permite explorar el comportamiento de la morbilidad aguda, tanto respiratoria como circulatoria, en la población de los municipios del Valle de Aburrá entre los años 2008 y 2019.</p>
                </div>
            </div>
            <h2 className="font-bold text-primary text-lg text-center mb-4">{title}</h2>
            
            {reports.map((reportSrc, index) => (
                <div key={index} className="w-full h-screen mb-20">
                    <iframe
                        allowFullScreen={true}
                        src={reportSrc}
                        title={`Report ${index + 1}`}
                        className="w-full h-full border border-gray-300 rounded-md"
                    ></iframe>
                    <div className="flex justify-center mt-4 space-x-4">
                        <button onClick={() => window.location.reload()} className="bg-primary text-textColor px-4 py-2 rounded-md">Limpiar Consulta</button>
                        <button onClick={() => window.print()} className="bg-primary text-textColor px-4 py-2 rounded-md">Imprimir/Guardar PDF</button>
                    </div>
                </div>
            ))}
        </main>
    );
};

export default Morbilidad;
