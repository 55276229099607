import React, { useEffect, useState } from 'react';

interface PQRS {
    _id: string;
    username: string;
    email: string;
    message: string;
    createdAt: string;
    status: string;
    resolvedAt?: string;
}

const AdminPQRS: React.FC = () => {
    const [pqrsList, setPqrsList] = useState<PQRS[]>([]);
    const API_URL = process.env.REACT_APP_API_URL;
    useEffect(() => {
        const fetchPQRS = async () => {
            try {
                const response = await fetch(`${API_URL}/pqrs`);
                if (response.ok) {
                    const data = await response.json();
                    setPqrsList(data);
                } else {
                    console.error('Error al cargar las PQRS');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchPQRS();
    }, []);

    const updateStatus = async (id: string, status: string) => {
        try {
            const response = await fetch(`${API_URL}/pqrs/${id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ status }),
            });

            if (response.ok) {
                const updatedPQRS = await response.json();

                setPqrsList((prevList) =>
                    prevList.map((item) =>
                        item._id === updatedPQRS.data._id ? updatedPQRS.data : item
                    )
                );
            } else {
                console.error('Error al actualizar la PQRS');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center md:h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-2xl md:text-5xl font-bold">PQRS que han reportado los usuarios</h1>
                </div>
            </div>
            <div className="container overflow-x-auto p-2 sm:p-8 mx-auto">
                <table className="min-w-full border border-gris2 text-left">
                    <thead>
                        <tr className="bg-gris1 text-center">
                            <th className="p-2 border border-gris2">Nombre</th>
                            <th className="p-2 border border-gris2">Email</th>
                            <th className="p-2 border border-gris2">Fecha de Creación</th>
                            <th className="p-2 border border-gris2">Estado</th>
                            <th className="p-2 border border-gris2">Fecha de Finalización</th>
                            <th className="p-2 border border-gris2">PQRS</th>
                            <th className="p-2 border border-gris2">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pqrsList.map((pqrs) => (
                            <tr key={pqrs._id} className="hover:bg-gray-50">
                                <td className="p-2 border border-gris2">{pqrs.username}</td>
                                <td className="p-2 border border-gris2">{pqrs.email}</td>
                                <td className="p-2 border border-gris2">
                                    {new Date(pqrs.createdAt).toLocaleString('es-CO', {
                                        dateStyle: 'short',
                                        timeStyle: 'short',
                                    })}
                                </td>
                                <td className="p-2 border border-gris2">
                                    {pqrs.status === 'pending'
                                            ? 'Pendiente'
                                            : pqrs.status === 'approved'
                                            ? 'Solucionado'
                                            : 'No Aplica'}</td>
                                <td className="p-2 border border-gris2">
                                    {pqrs.resolvedAt
                                    ? new Date(pqrs.resolvedAt).toLocaleString('es-CO', {
                                        dateStyle: 'short',
                                        timeStyle: 'short',
                                    })
                                    : '-'}
                                </td>
                                <td className="p-2 border border-gris2">{pqrs.message}</td>
                                <td className="p-2 border border-gris2 text-center">
                                    <button
                                        className={`px-4 py-2 rounded-md mb-1 ${
                                            pqrs.status !== 'pending' ? 'bg-gray-400 text-gray-600 cursor-not-allowed' : 'bg-primary text-white hover:bg-primary/80'
                                        }`}
                                        onClick={() => updateStatus(pqrs._id, 'solved')}
                                        disabled={pqrs.status !== 'pending'}
                                    >
                                        Falla Resuelta
                                    </button>
                                    <button
                                        className={`px-4 py-2 rounded-md ${
                                            pqrs.status !== 'pending' ? 'bg-gray-400 text-gray-600 cursor-not-allowed' : 'bg-red-600 text-white hover:bg-red-500'
                                        }`}
                                        onClick={() => updateStatus(pqrs._id, 'NA')}
                                        disabled={pqrs.status !== 'pending'}
                                    >
                                        No Aplica Falla
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </main>
    );
};

export default AdminPQRS;
