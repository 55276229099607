import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Authentication';

const AgudosEvent: React.FC = () => {
    const { user } = useAuth();
    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center md:h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Efectos Agudos en Salud</h1>
                    <br/>
                    <div className='grid grid-cols-3 font-bold underline'>
                        <Link to="/eventos/agudos/analisis-riesgos" className="block px-2 py-2 hover:bg-primary rounded-md">Análisis de Riesgo</Link>
                        <Link to="/eventos/agudos/morbilidad" className="block px-2 py-2 hover:bg-primary rounded-md">Morbilidad</Link>
                        <Link to="/eventos/agudos/mortalidad" className="block px-2 py-2 hover:bg-primary rounded-md">Mortalidad</Link>
                    </div>
                </div>
            </div>
            <div className="p-2 sm:p-4 text-base md:text-lg max-w-screen-lg mx-auto">
                <p className="text-xl font-bold text-center p-4 border-b-2 border-primary ">
                    ¿Cuáles son los efectos en salud agudos relacionados a la calidad del aire?
                </p>
                <p className='text-center'>La calidad del aire está estrechamente relacionada con ciertos efectos en salud agudos, particularmente en enfermedades circulatorias y respiratorias. A continuación, se detallan las principales afecciones vinculadas:</p>
                <br/>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <h2 className="font-bold mb-2 text-primary">Enfermedades Circulatorias</h2>
                        <ul className="list-disc ml-6 space-y-2">
                            <li>Enfermedades Cerebrovasculares (I60-I69)</li>
                            <li>Enfermedades Isquémicas del Corazón (I20-I25)</li>
                        </ul>
                        {user && user?.features?.rol === 'Técnico' && (
                        <div>
                            <br/>
                                <a
                                    href="/Documents/Lista_Eventos_Circulatorios.pdf"
                                    download
                                    className="flex items-center justify-center text-center text-base text-blue-600 hover:underline mb-1"
                                >
                                    Listado de Efectos de la Salud relacionados a la Calidad del Aire de Enfermedades Circulatorias 
                                </a>
                            
                        </div>
                        )}
                    </div>
                    <div>
                        <h2 className="font-bold mb-2 text-primary">Enfermedades Respiratorias</h2>
                        <ul className="list-disc ml-6 space-y-2">
                            <li>Infecciones Agudas de las Vías Respiratorias Superiores (J00–J06)</li>
                            <li>Influenza (Gripe) y Neumonía (J09–J18)</li>
                            <li>Otras Enfermedades de las Vías Respiratorias Superiores (J30–J39)</li>
                            <li>Otras Infecciones Agudas de las Vías Respiratorias Inferiores (J20–J22)</li>
                            <li>Enfermedades del Oído Medio y de la Mastoides (H65–H75)</li>
                        </ul>
                        {user && user?.features?.rol === 'Técnico' && (
                        <div>
                            <br/>
                                <a
                                    href="/Documents/Lista_Eventos_Respiratorios.pdf"
                                    download
                                    className="flex items-center justify-center text-center text-base text-blue-600 hover:underline mb-1"
                                >
                                    Listado de Efectos de la Salud relacionados a la Calidad del Aire de Enfermedades Respiratorias
                                </a>
                            
                        </div>
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default AgudosEvent;
